import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withKeys as _withKeys, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/jack-o-lantern.png'


const _hoisted_1 = { class: "navbar navbar-expand-lg navbar-dark bg-dark" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = {
  class: "collapse navbar-collapse",
  id: "navbarTogglerDemo02"
}
const _hoisted_4 = { class: "navbar-nav me-auto mb-2 mb-lg-0" }
const _hoisted_5 = { class: "nav-item" }
const _hoisted_6 = { class: "nav-item" }
const _hoisted_7 = { class: "nav-item" }
const _hoisted_8 = { class: "nav-item" }
const _hoisted_9 = { class: "nav-item" }
const _hoisted_10 = {
  class: "nav-link",
  href: "https://discord.gg/ZvRTYqTWtZ",
  target: "_blank"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        class: "navbar-brand",
        to: "/"
      }, {
        default: _withCtx(() => [
          _cache[3] || (_cache[3] = _createElementVNode("img", {
            src: _imports_0,
            alt: "Logo",
            width: "30",
            height: "24",
            class: "d-inline-block align-text-top"
          }, null, -1)),
          _createTextVNode(" " + _toDisplayString(_ctx.siteName), 1)
        ]),
        _: 1
      }),
      _cache[10] || (_cache[10] = _createElementVNode("button", {
        class: "navbar-toggler",
        type: "button",
        "data-bs-toggle": "collapse",
        "data-bs-target": "#navbarTogglerDemo02",
        "aria-controls": "navbarTogglerDemo02",
        "aria-expanded": "false",
        "aria-label": "Toggle navigation"
      }, [
        _createElementVNode("span", { class: "navbar-toggler-icon" })
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("ul", _hoisted_4, [
          _createElementVNode("li", _hoisted_5, [
            _createVNode(_component_router_link, {
              class: "nav-link",
              to: "/"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-house" }),
                _cache[4] || (_cache[4] = _createTextVNode(" Home"))
              ]),
              _: 1
            })
          ]),
          _createElementVNode("li", _hoisted_6, [
            _createVNode(_component_router_link, {
              class: "nav-link",
              to: "/movies"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-film" }),
                _cache[5] || (_cache[5] = _createTextVNode(" Movies"))
              ]),
              _: 1
            })
          ]),
          _createElementVNode("li", _hoisted_7, [
            _createVNode(_component_router_link, {
              class: "nav-link",
              to: "/shows"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-tv" }),
                _cache[6] || (_cache[6] = _createTextVNode(" TV Shows"))
              ]),
              _: 1
            })
          ]),
          _createElementVNode("li", _hoisted_8, [
            _createVNode(_component_router_link, {
              class: "nav-link",
              to: "/halloween"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-ghost" }),
                _cache[7] || (_cache[7] = _createTextVNode(" Halloween"))
              ]),
              _: 1
            })
          ]),
          _createElementVNode("li", _hoisted_9, [
            _createElementVNode("a", _hoisted_10, [
              _createVNode(_component_font_awesome_icon, { icon: "fa-brands fa-discord" }),
              _cache[8] || (_cache[8] = _createTextVNode(" Discord"))
            ])
          ])
        ]),
        _createElementVNode("form", {
          class: "d-flex",
          role: "search",
          rel: "search",
          name: "navSearch",
          onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitClick && _ctx.submitClick(...args)), ["stop","prevent"]))
        }, [
          _withDirectives(_createElementVNode("input", {
            onKeyup: _cache[0] || (_cache[0] = _withKeys(
//@ts-ignore
(...args) => (_ctx.submitClick && _ctx.submitClick(...args)), ["enter"])),
            class: "form-control search-input",
            type: "search",
            placeholder: "Search",
            "aria-label": "Search",
            form: "navSearch",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.query) = $event)),
            autocomplete: "on",
            minlength: "1",
            required: ""
          }, null, 544), [
            [_vModelText, _ctx.query]
          ]),
          _cache[9] || (_cache[9] = _createElementVNode("button", {
            class: "btn btn-success search-button",
            type: "submit"
          }, "Search", -1))
        ], 32)
      ])
    ])
  ]))
}